import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import AppComponent from './app/AppComponent';
import './app.scss';
import { ContextProvider } from './shared/provider';

const root = createRoot(document.getElementById('app'));
root.render(<ContextProvider>
  <BrowserRouter>
    <AppComponent />
  </BrowserRouter>
</ContextProvider>);
