export const combineReducers = (reducers) => {
  const globalState = {};

  Object.entries(reducers).forEach(([key, value]) => {
    if (typeof value === 'function') {
      globalState[key] = value(undefined, { type: 'INIT' });
    } else {
      throw new Error(`${value} is not a function`);
    }
  });

  const reducerFunction = (state, action) => {
    let hasStateChanged = false;
    const updatedState = {};

    Object.keys(reducers).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(reducers, key)) {
        const currentState = state[key];
        const currentReducer = reducers[key];
        const newState = currentReducer(currentState, action);
        hasStateChanged = hasStateChanged || (newState !== currentState);

        updatedState[key] = newState;
      }
    });
    return hasStateChanged ? updatedState : state;
  };

  return [globalState, reducerFunction];
};
