const initialState = 0;

export default function currentLevel(state = initialState, action = null) {
  const nextState = state;

  if (action.type === 'SET_LEVEL') {
    return action.level;
  }

  return nextState;
}
