import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const AppComponent = () => {
  const SpellingContainer = lazy(() => import('./spcn2020/SpellingContainer'));
  const HandwritingComponent = lazy(() => import('./hw/HandwritingComponent'));
  const LandingPageComponent = lazy(() => import('./landing/LandingPageComponent'));

  const LandingPage = () => (
    <Suspense><LandingPageComponent /></Suspense>
  );

  const SpellingPage = () => (
    <Suspense><SpellingContainer /></Suspense>
  );

  const HandwritingPage = () => (
    <Suspense><HandwritingComponent /></Suspense>
  );

  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/q" element={<LandingPage />} />
      <Route exact path="/q/spcn2020" element={<SpellingPage />} />
      <Route path="/q/spcn2020/:code" element={<SpellingPage />} />
      <Route exact path="/q/hw" element={<HandwritingPage />} />
      <Route path="/q/hw/:code" element={<HandwritingPage />} />
    </Routes>
  );
};

export default AppComponent;
