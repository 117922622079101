import React, { useCallback, useMemo, useReducer } from 'react';
import { combineReducers } from './combine-reducers';
import { reducer } from './reducers';

const AppStateProvider = React.createContext();

const ContextProvider = ({ children }) => {
  const reducers = useCallback(() => combineReducers(reducer), [combineReducers]);

  const [initialState, mainReducer] = reducers();
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <AppStateProvider.Provider value={contextValue}>{children}</AppStateProvider.Provider>;
};

export { ContextProvider, AppStateProvider as ContextConsumer };
